<template>
  <div>

    <h1 class="font-medium cursor-default text-2xl">
      Eventos em

      <div class="inline-flex items-center cursor-pointer" @click.stop.prevent="openDialogButton">
        <span v-if="currentCity" class="font-normal tracking-normal">{{ currentCity.id ? `${currentCity.name}/${currentCity.uf}` : currentCity }}</span>
        <span v-else>Curitiba</span>
        <!-- <div v-else class="animate-pulse translate-y-1 h-6 bg-gray-100 rounded-full dark:bg-zinc-800 w-32">Curitiba</div> -->
        <icon-chevron-down />
      </div>
    </h1>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="transform origin-top scale-y-0 opacity-0"
      enter-to-class="transform origin-top scale-y-100 opacity-100"
      leave-active-class="transition ease-in duration-200"
      leave-from-class="transform origin-top scale-y-100 opacity-100"
      leave-to-class="transform origin-top scale-y-0 opacity-0">
      <div v-if="isCitySelectorModalOpen" class="my-4 border p-4">
        <!-- <h2>Selecione a cidade</h2> -->
        <div class="md:grid md:grid-flow-col md:grid-cols-none md:auto-cols-auto md:auto-rows-auto md:space-x-4">
          <div class="p-2" v-for="uf in Object.keys(filteredCitiesByUF)" :key="uf">
            <h3 class="font-bold opacity-60">{{ uf }}</h3>
            <a v-for="city in filteredCitiesByUF[uf]" :key="`idx-${city.name}`"
              class="cursor-pointer block"
              @click="selectCity(city)">
                {{ city.name }} <small>({{ city.count }})</small>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>

const filteredCitiesByUF = computed(() => {
  return cities.value.reduce((acc, city) => {
    if (city.name && city.uf) {
      if (!acc[city.uf]) {
        acc[city.uf] = [];
      }
      acc[city.uf].push(city);
    }
    return acc;
  }, {});
});

import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user.js'

const userStore = useUserStore()
const { loggedIn: isLoggedIn } = storeToRefs(userStore)

const emit = defineEmits(['onCitySelected'])

const currentCity = ref();
const isCitySelectorModalOpen = ref(false);

const route = useRoute()
const router = useRouter()

const runtimeConfig = useRuntimeConfig()
const { baseApiUrl: baseUrl } = runtimeConfig.public

const { data: cities } = await useFetch(() => `${baseUrl}/api/cities.json`)
currentCity.value = cities.value.find(city => city.slug === route.params.city)

watch(route, () => {
  isCitySelectorModalOpen.value = false;
});

const selectCity = async (city) => {
  // await userStore.changeCity(cityName);
  isCitySelectorModalOpen.value = false;
  currentCity.value = city.name
  emit('onCitySelected', city)
}

const openDialogButton = () => {
  isCitySelectorModalOpen.value = !isCitySelectorModalOpen.value
}

</script>
